import React from 'react'
import Footer from '../../components/Footer'
import './in-progress.scss'

const InProgress = () => {
  return (
    <div className="in-progress-container">
      <div className="mx-auto my-auto h-100-fixed">
        <h1 className="mt-5">In - Progress</h1>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  )
}

export default InProgress
